export default class LocalStorageService {
    public static setValue(key: string, value: string | number): void {
        if (typeof window === "undefined") {
            return;
        }

        window.localStorage.setItem(key, value.toString());
    }

    public static getValue(key: string, defaultData?: string | number): string | number | null {
        if (typeof window === "undefined") {
            return null;
        }

        const value = window.localStorage.getItem(key) || "";

        if(!value && defaultData) {
            return defaultData;
        }

        if(!value) {
            return null;
        }

        if(+value) {
            return +value;
        }

        return value;
    }

    public static setData(key: string, data: Object): void {
        if (typeof window === "undefined") {
            return;
        }

        window.localStorage.setItem(key, JSON.stringify(data));
    }

    public static getData(key: string): any {
        if (typeof window === "undefined") {
            return null;
        }

        const data = window.localStorage.getItem(key) || "";

        if (!data) {
            return null;
        }

        return JSON.parse(data);
    }
}
