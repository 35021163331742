import { type ReactElement } from "react";
// import { default as ThemeControlButton } from "@components/SettingsControlButton";
// import { useDispatch, useSelector } from "react-redux";
// import { setCurrentTheme } from "@store/slices/application";
// import { TRootState } from "@store";
// import { themeSettingsData, ThemeSettingsKeys } from "@utils/configs/themeConfigData";
import "./style.css";

const ActionsBar = (): ReactElement => {
    // const dispatch = useDispatch();
    
    // const appTheme = useSelector(
    //     (state: TRootState) => state.application.currentTheme
    // );

    // const handleThemeChange = (newValue: number | string): void => {
    //     dispatch(setCurrentTheme(newValue));
    // };

    return (
        <div className="actions-bar">
            {/* <ThemeControlButton
                iconName={themeSettingsData[ThemeSettingsKeys.CLASS_NAME].iconName}
                currentValue={appTheme}
                values={themeSettingsData[ThemeSettingsKeys.CLASS_NAME].values}
                onValueChange={handleThemeChange}
            /> */}
        </div>
    );
};

export default ActionsBar;
