import { Fragment, useState } from "react";
import ModalDialog from "@components/ModalDialog";
import FeedbackForm from "./FeedbackForm";
import OpenFormButton from "./OpenFormButton";
import { ModalLayouts } from "@layouts/ModalLayout";
import "./styles.css";

const FeedbackFormModal = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <Fragment>
            <ModalDialog
                isModalDialogOpen={isModalVisible}
                layoutType={ModalLayouts.LOCKER}
                handleClose={() => setIsModalVisible(false)}
            >
                <FeedbackForm closeForm={() => setIsModalVisible(false)} />
            </ModalDialog>
            {/* <div className="ready-alert" className="{ 'hidden': !isRequestSent }">
                Thank you! Our technical support will get in touch with you soon!
            </div> */}
            <OpenFormButton openForm={() => setIsModalVisible(true)} />
        </Fragment>
    )
}

export default FeedbackFormModal;