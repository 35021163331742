import { useState, type ReactElement } from "react";
import { ModalLayouts } from "@layouts/ModalLayout";
import { useDispatch } from "react-redux";
import { deleteAlert, setAlertIsDisplayed } from "@store/slices/alerts";
import Button from "@mui/material/Button";
import ModalDialog from "@components/ModalDialog";
import AlertFactory from "@domains/Alert";
import "./style.css";

export interface IAlertDialog {
    title?: string;
    alertText: string;
    firstButtonLabel: string;
    secondButtonLabel: string;
    onSubmitAction: () => void;
    onCancelAction: () => void;
}

const AlertDialogContent = ({
    title,
    alertText,
    firstButtonLabel,
    secondButtonLabel,
    onCancelAction,
    onSubmitAction,
}: IAlertDialog): ReactElement => {
    return (
        <div className="alert-dialog-container">
            {title && <div className="alert-dialog-title">{title}</div>}
            <div className="alert-dialog-content">{alertText}</div>
            <div className="alert-dialog-buttons">
                <Button variant="text" size="medium" onClick={onSubmitAction}>
                    {firstButtonLabel}
                </Button>
                {secondButtonLabel.length > 0 && (
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={onCancelAction}
                    >
                        {secondButtonLabel}
                    </Button>
                )}
            </div>
        </div>
    );
};

const AlertDialog = ({
    alertBox,
}: {
    alertBox: AlertFactory;
}): ReactElement => {
    const title = alertBox.getTitle();
    const alertText = alertBox.getMessage();
    const onSubmitAction = alertBox.getSubmitAction();
    const firstButtonLabel = alertBox.getSubmitButtonLabel();
    const secondButtonLabel = alertBox.getCancelButtonLabel();

    const [isModalDialogOpen, setIsModalDialogOpen] = useState<boolean>(true);
    const dispatch = useDispatch();

    const onAlertCancel = (): void => {
        setIsModalDialogOpen(false);
        dispatch(setAlertIsDisplayed(false));
        dispatch(deleteAlert(true));
    };

    const onAlertSubmit = (): void => {
        onSubmitAction();
        setIsModalDialogOpen(false);
    };

    return (
        <div className="alert-dialog">
            <ModalDialog
                isModalDialogOpen={isModalDialogOpen}
                layoutType={ModalLayouts.LOCKER}
            >
                <AlertDialogContent
                    title={title}
                    alertText={alertText}
                    firstButtonLabel={firstButtonLabel}
                    secondButtonLabel={secondButtonLabel}
                    onSubmitAction={onAlertSubmit}
                    onCancelAction={onAlertCancel}
                />
            </ModalDialog>
        </div>
    );
};

export default AlertDialog;
