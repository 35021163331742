import { Alert } from "@mui/material";
import { ReactNode } from "react";
import "./style.css";

export interface IErrorsListProps {
    errors: string[];
}

const ErrorsList = ({ errors }: IErrorsListProps): ReactNode => {
    if (!errors?.length) {
        return null;
    }

    return (
        <div className="default-errors-list">
            {errors.map((error: any) => (
                <Alert key={error} color="error" severity="error">
                    {error}
                </Alert>
            ))}
        </div>
    );
};

export default ErrorsList;
