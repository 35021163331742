import { type ReactElement } from "react";
import "./style.css";

interface ITabProps {
	tabTitle: string;
	isActiveTab: boolean;
	setActive: () => void;
}

const PreviewAreaTab = ({
	tabTitle,
	isActiveTab,
	setActive,
}: ITabProps): ReactElement => {
	return (
		<div
			className={`preview-area-tab ${isActiveTab && "active"}`}
			onClick={setActive}
		>
			<div className="preview-area-tab-body">{tabTitle}</div>
		</div>
	);
};

export default PreviewAreaTab;
