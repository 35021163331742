import React, { useState } from "react";
import OutlinedDropDown from ".";

const OutlinedDropDownWrapper = () => {
    const [value, setValue] = useState("happiness");

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div>
            <OutlinedDropDown
                label="Mood"
                helperTxt="Select your mood"
                list={[
                    { value: "happiness", label: "Happiness" },
                    { value: "surprise", label: "Surprise" },
                ]}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};

const outlinedDropdownData = {
    component: <OutlinedDropDownWrapper />,
    import: "import OutlinedDropDown from '../../common/OutlinedDropdown';",
    usage: `<OutlinedDropDown\n\tlabel={"Mood"}\n\thelperTxt={"Select your mood"}\n\tlist={[\n\t\t{ value: "happiness", label: "Happiness" },\n\t\t{ value: "surprise", label: "Surprise" }\n\t]}\n\tvalue={value}\n\tonChange={(event) => setValue(event.target.value)}\n/>`,
    props: {
        label: `The label displayed above the dropdown.\n\nExample:\nlabel="Mood"\n\n`,
        helperTxt: `Helper text displayed below the dropdown, providing guidance to the user.\n\nExample:\nhelperTxt="Select your mood"\n\n`,
        list: `An array of objects defining the options in the dropdown list. Each object should have a "value" and a "label" field.\n\nExample:\nlist={[\n\t{ value: "happiness", label: "Happiness" },\n\t{ value: "surprise", label: "Surprise" }\n]}\n\n`,
        value: `The selected value from the list options.\n\nUsage with useState:\n\tconst [value, setValue] = useState("happiness");\n\nIn the dropdown, set "value" to this state variable:\n\tvalue={value}\n\nThis allows the dropdown to display the selected value and update it dynamically.\n\n`,
        onChange: `A function triggered when the selected option changes.\n\nUsage with useState:\nonChange={(event) => setValue(event.target.value)}\n\nThis updates the "value" state each time the user selects a different option from the dropdown.`,
    },
};

export default outlinedDropdownData;
