import { type ReactElement } from "react";
import { AddIcon, UploadIcon } from "./icons";
import "./style.css";

export interface IToolBarProps {
    onAddFile: () => void;
    onUploadFile: () => void;
}

export interface IToolBarButton {
    key: string;
    title: string;
    icon: ReactElement;
    action: () => void;
}

const EditorToolbar = ({
    onAddFile,
    onUploadFile,
}: IToolBarProps): ReactElement => {
    const toolbarButtons: IToolBarButton[] = [
        {
            key: "add",
            icon: <AddIcon />,
            title: "Create file",
            action: onAddFile,
        },
        {
            key: "upload",
            icon: <UploadIcon />,
            title: "Upload file",
            action: onUploadFile,
        },
    ];

    return (
        <div className="editor-toolbar">
            {toolbarButtons.map((toolbarButton) => (
                <button
                    className="editor-toolbar-button"
                    key={toolbarButton.key}
                    title={toolbarButton.title}
                    onClick={toolbarButton.action}
                >
                    {toolbarButton.icon}
                </button>
            ))}
        </div>
    );
};

export default EditorToolbar;
