import { type ReactElement } from "react";
import "./style.css";

export interface IOutlinedButtonProps {
    label: string;
    action: () => void;
}

const OutlinedButton = ({
    label,
    action,
}: IOutlinedButtonProps): ReactElement => {
    const onClickHandle = (): void => {
        action();
    };

    return (
        <div className="outlined-button-container">
            <button className="outlined-button" onClick={onClickHandle}>
                {label}
            </button>
        </div>
    );
};

export default OutlinedButton;
