import { ReactElement, ReactNode } from "react";
import ComponentsLibrary from "@components/ComponentsLibrary";
import PreviewAreaPanel from "@components/PreviewAreaPanel";
import "./style.css";

export interface ITabsData {
    label: string;
    component: ReactNode;
}

export type TTabComponent = ReactNode;

export const previewTabs: ITabsData[] = [
    {
        label: "preview area",
        component: <PreviewAreaPanel />,
    },
    {
        label: "library",
        component: <ComponentsLibrary />,
    },
];

export const tabsComponents: ReactElement[] = [
    <PreviewAreaPanel />,
    <ComponentsLibrary />,
];
