import { type ReactElement } from "react";
import { TRootState } from "@store";
// import { deleteAlert } from "@store/slices/alerts";
import { useSelector } from "react-redux";
import Alerts, { AlertTypes } from "@domains/Alert";
import AlertDialog from "@components/AlertDialog";
import MUIAlert, { TAlertColor } from "@components/MUIAlert";
import "./style.css";

const Alert = (): ReactElement => {
    const alertBox: Alerts | null = useSelector(
        (state: TRootState) => state.alerts.alertBox
    );
    const alertType: AlertTypes | "" = useSelector(
        (state: TRootState) => state.alerts.type
    );

    // const dispatch = useDispatch();

    // const buttonAction = () => {
    //     dispatch(deleteAlert(true));
    // };

    if (!alertBox) {
        return <></>;
    }

    if (alertType === AlertTypes.DIALOG) {
        return <AlertDialog alertBox={alertBox} />;
    }

    if (alertType === AlertTypes.ERROR) {
        return (
            <MUIAlert
                alertColor={alertBox.getType().toLowerCase() as TAlertColor}
                title={alertBox.getTitle()}
                alertText={alertBox.getMessage()}
            />
        );
    }

    return (
        <MUIAlert
            alertColor={alertBox.getType().toLowerCase() as TAlertColor}
            title={alertBox.getTitle()}
            alertText={alertBox.getMessage()}
        />
    );
};

export default Alert;
