import { Provider } from "react-redux";
import ReactDOM, { type Root } from "react-dom/client";
import setupResizeObserver from "@domains/ResizeObserver";
import App from "@components/App";
import store from "@store";

const root: Root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

setupResizeObserver();

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
