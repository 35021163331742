import { ReactElement, useState } from "react";
import SplitBar, { type TPanelWidth } from "@components/SplitBar";
import PreviewArea from "@components/PreviewArea";
import CodeEditorTab from "@components/CodeEditorTab";
import "./style.css";

const WorkspacePanels = (): ReactElement => {
    const [panelsWidth, setPanelsWidth] = useState<TPanelWidth>({
        left: "50%",
        right: "50%",
    });

    return (
        <div className="work-space-panels">
            <div className="left-panel" style={{ width: panelsWidth.left }}> {/* CodeEditorPanel */}
                <CodeEditorTab /> {/* ContentTabs -> TabController + TabPanel(active) */}
            </div>
            <SplitBar setPanelWidth={setPanelsWidth} />
            <div className="right-panel" style={{ width: panelsWidth.right }}> {/* DeveloperToolsPanel */}
                <PreviewArea />
            </div>
        </div>
    );
};

export default WorkspacePanels;
