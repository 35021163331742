import { type TUserFiles } from "@domains/File";
import { createContext, Dispatch, SetStateAction } from "react";

export interface IWorkspaceContext {
    files: TUserFiles;
    setFiles: any;
    previewSourceUrl: string;
    isPreviewCompiling: boolean;
    compilationErrors: string[];
    setCompilationErrors: Dispatch<SetStateAction<string[]>>;
    compileFiles: () => void;
    stopCompiling: () => void;
}

const FileExplorerContext = createContext({} as IWorkspaceContext);

export default FileExplorerContext;