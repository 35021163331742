import { ReactElement, useContext } from "react";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import CompileButton from "@components/CompileButton";
import "./style.css";
import WorkspaceContext from "@context/WorkspaceContext";

const PreviewAreaStub = (): ReactElement => {

    const { compileFiles } = useContext(WorkspaceContext)

    return (
        <div className="stub-container">
            <div className="stub-icon">
                <SvgSpriteIcon className={"singularity-not-compile"} id={"singularity-not-compile"} />
            </div>
            <div className="stub-info">
                <div className="stub-title">Nothing here yet...</div>
                <div className="stub-body">
                    <div className="stub-body-line">
                        <span className="stub-span">Press</span>
                        <CompileButton isDisabled={false} isCompiling={false} onClick={compileFiles} />
                        <span className="stub-span">to run</span>
                    </div>
                    <div className="stub-body-line">preview</div>
                </div>
            </div>
        </div>
    )
}

export default PreviewAreaStub;