import { type ReactElement, type MouseEvent, useState } from "react";
import { capitalize } from "@services/textService";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export interface IComponentSelectProps {
    label?: string;
    options: string[];
    defaultSelectedValue?: string;
    onSelect: (value: string) => void;
}

const ComponentSelect = ({
    options,
    label = "AlertBox Info",
    defaultSelectedValue = "AlertBox Info",
    onSelect,
}: IComponentSelectProps): ReactElement => {
    if (label === "") {
        label = "Select a component";
    }
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] =
        useState<string>(defaultSelectedValue);

    const handleOpenSelect = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setIsSelectOpen(true);
        document.addEventListener("click", handleCloseSelect);
    };

    const handleCloseSelect = (): void => {
        setIsSelectOpen(false);
        document.removeEventListener("click", handleCloseSelect);
    };

    const handleSelect = (
        event: MouseEvent<HTMLElement>,
        option: string
    ): void => {
        event.stopPropagation();
        setSelectedValue(option);
        onSelect(option);
        handleCloseSelect();
    };

    const SelectHead = (): ReactElement => {
        return (
            <div
                className={`component-select-wrapper ${
                    isSelectOpen && "options-shown"
                }`}
            >
                <div className="component-select-label">
                    <span className="component-select-text">{label}</span>
                </div>
                <div className="component-select-arrow">
                    <SvgSpriteIcon
                        className={"main-logo"}
                        id={"select-arrow"}
                    />
                </div>
            </div>
        );
    };

    const Options = (): ReactElement => {
        return (
            <div
                className={`component-select-options-container ${
                    isSelectOpen && "shown"
                }`}
            >
                {options.map((option: string) => {
                    return (
                        <div
                            className="component-select-option-container"
                            key={option}
                            onClick={(event) => handleSelect(event, option)}
                        >
                            <div className="component-select-option">
                                <span
                                    className={`component-option-text ${
                                        selectedValue === option &&
                                        "selected"
                                    }`}
                                >
                                    {capitalize(option)}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="component-select" onClick={handleOpenSelect}>
            <SelectHead />
            <Options />
        </div>
    );
};

export default ComponentSelect;
