import JSZip, { type JSZipObject } from "jszip";
import UserFile, {
    TUserFileContent,
    TUserFiles,
    TUserFilesError,
} from "@domains/File";

export default class ZipService {
    public static downloadZip(zip: JSZip): void {
        zip.generateAsync({ type: "blob" }).then((blob: Blob) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = "service.zip";
            downloadLink.click();
        });
    }

    public static createZip(files: UserFile[]): JSZip {
        const ZIP = new JSZip();

        files.forEach((file) => {
            const content: TUserFileContent = file.getContent();

            if (!content) {
                return;
            }

            ZIP.file(file.getFullName(), content);
        });

        return ZIP;
    }

    public static async importZipTemplate(
        templateName: string
    ): Promise<TUserFiles | null> {
        try {
            const response = await fetch(`/assets/templates/${templateName}`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const file = await response.blob();
            const unpackedFiles: TUserFiles = (await ZipService.getFilesFromZip(
                file as File
            )) as TUserFiles;

            return unpackedFiles;
        } catch (error) {
            console.error("Error loading project template:", error);
            return null;
        }
    }

    public static async getFilesFromZip(
        file: File
    ): Promise<TUserFiles | TUserFilesError> {
        const ZIP = new JSZip();
        const unpackedFiles: JSZipObject[] = Object.values(
            (await ZIP.loadAsync(file)).files
        );

        if (!unpackedFiles?.length) {
            return [];
        }

        if (this.doesArchiveContainFolders(unpackedFiles)) {
            return "Folder exists error";
        }

        const files: UserFile[] = await Promise.all(
            unpackedFiles.map(async (unpackedFile) => {
                const file: UserFile = new UserFile();
                file.setContent(await unpackedFile.async("string"));
                file.setFullName(unpackedFile.name);
                return file;
            })
        );

        return files;
    }

    public static doesArchiveContainFolders(
        unpackedFiles: JSZipObject[]
    ): boolean {
        if (!unpackedFiles?.length) {
            return false;
        }

        if (unpackedFiles.some((unpackedFile) => unpackedFile.dir)) {
            return true;
        }

        return false;
    }
}
