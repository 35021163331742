import { ReactElement } from "react";
import { capitalize } from "@services/textService";
import { CompileButtonLabels } from "./meta";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export interface ICompileButtonProps {
    isDisabled: boolean;
    isCompiling: boolean;
    onClick: () => void;
}

const CompileButton = ({
    isDisabled,
    isCompiling,
    onClick,
}: ICompileButtonProps): ReactElement => {
    const iconName: string = !isCompiling
        ? CompileButtonLabels.COMPILE
        : CompileButtonLabels.STOP_COMPILE;

    const buttonLabel: string = !isCompiling
        ? CompileButtonLabels.COMPILE
        : CompileButtonLabels.CANCEL;

    return (
        <div
            className={`compile-button ${isDisabled && "disabled"}`}
            onClick={onClick}
        >
            <SvgSpriteIcon
                className={`${iconName}-icon`}
                id={`${iconName}-icon`}
            />
            <span className={`${buttonLabel}-button-label`}>
                {capitalize(buttonLabel)}
            </span>
        </div>
    );
};

export default CompileButton;
