import {
    IUserFileSerializable,
    TUserFilesError,
    type TUserFiles,
} from "@domains/File";
import { replaceFiles, setCurrentFileId } from "@store/slices/files";
import { type ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuButton } from "@components/MainMenu";
import { AlertTypes } from "@domains/Alert";
import { TRootState } from "@store";
import useAlertsFactory from "@hooks/useAlertsFactory";
import FilesService from "@services/FilesService";
import ZipService from "@services/ZipService";
import type TUserFile from "@domains/File";
import Loader from "@components/Loader";
import { FileIOErrorsTypes, FileIOErrors, UploadButtonTypes } from "./meta";
import "./style.css";

export type TUploadButtonTypes =
    | UploadButtonTypes.STYLED
    | UploadButtonTypes.PAINTED;

export interface IZipManagerButton {
    buttonLabel: string;
    type?: TUploadButtonTypes;
    svgName?: string;
    extraAction?: () => void;
}

function isTUserFilesError(
    result: TUserFiles | TUserFilesError
): result is TUserFilesError {
    return typeof result === "string";
}

export const UploadButton = ({
    buttonLabel,
    extraAction,
    type = UploadButtonTypes.STYLED,
    svgName = "",
}: IZipManagerButton): ReactElement => {
    const dispatch = useDispatch();
    const [isUploaded, setIUploaded] = useState<boolean>(true);
    const { createErrorBox } = useAlertsFactory();
    let isIndexFileExist: boolean = false;

    const addFilesToWorkspace = (files: TUserFiles): void => {
        const serializableFiles: IUserFileSerializable[] =
            FilesService.toSerializableFiles(files);
        dispatch(replaceFiles(serializableFiles));
    };

    const handleZipUpload = (): void => {
        const fileInput: HTMLInputElement = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".zip";

        fileInput.addEventListener("change", async () => {
            try {
                const { files } = fileInput;

                if (!files?.length) {
                    return;
                }

                const unpackedFiles: TUserFile[] | TUserFilesError =
                    await ZipService.getFilesFromZip(files[0]);

                if (!unpackedFiles?.length) {
                    return;
                }

                if (isTUserFilesError(unpackedFiles)) {
                    createErrorBox(
                        FileIOErrorsTypes.UPLOAD,
                        FileIOErrors.FOLDER_EXISTS_ERROR
                    );
                    return;
                }

                unpackedFiles.forEach((file: TUserFile) => {
                    if (
                        file.getName() === "index" &&
                        file.getExtension() === "js"
                    ) {
                        isIndexFileExist = true;
                    }
                });

                if (!isIndexFileExist) {
                    createErrorBox(
                        FileIOErrorsTypes.UPLOAD,
                        FileIOErrors.NOT_INDEX_FILE_EXISTS_ERROR
                    );
                    return;
                }

                setIUploaded(false);
                addFilesToWorkspace(unpackedFiles);
                dispatch(setCurrentFileId(unpackedFiles[0].getId()));
                setIUploaded(true);
                extraAction && extraAction();
            } catch (e) {
                console.error("error", e);
                if (e instanceof Error) {
                    createErrorBox(FileIOErrorsTypes.UPLOAD, e.toString());
                }
            }
        });

        fileInput.click();
    };

    if (type === UploadButtonTypes.PAINTED) {
        return (
            <MenuButton
                svgName={svgName}
                label={buttonLabel}
                onClickAction={handleZipUpload}
            />
        );
    }

    return (
        <button className="styled-file-io-button" onClick={handleZipUpload}>
            {isUploaded ? buttonLabel : <Loader />}
        </button>
    );
};

export const DownloadButton = ({
    buttonLabel,
}: IZipManagerButton): ReactElement => {
    const { createCornedSuccessBox } = useAlertsFactory();
    const { createErrorBox } = useAlertsFactory();

    const [isDownloaded, setIsDownloaded] = useState<boolean>(true);

    const alertBoxType = useSelector((state: TRootState) => state.alerts.type);
    const isAlertDisplayed = useSelector(
        (state: TRootState) => state.alerts.isDisplayed
    );

    const serializedFiles = useSelector(
        (state: TRootState) => state.projectFiles.files
    );
    const workspaceFiles: TUserFiles =
        FilesService.fromSerializableFiles(serializedFiles);

    const handleZipDownload = (): void => {
        setIsDownloaded(false);

        const zip = ZipService.createZip(workspaceFiles);

        const indexFile = workspaceFiles.find(
            (file) => file.getName() === "index"
        );

        if (!indexFile) {
            createErrorBox(
                FileIOErrorsTypes.EXPORT,
                FileIOErrors.NOT_INDEX_FILE_EXISTS_ERROR
            );
            setIsDownloaded(true);
            return;
        }

        if (indexFile.getContent().length < 1) {
            createErrorBox("Export Error", FileIOErrors.EMPTY_FILE_ERROR);
            setIsDownloaded(true);
            return;
        }

        workspaceFiles.forEach((file) => {
            file.setDownloadedState(true);
        });

        ZipService.downloadZip(zip);

        setIsDownloaded(true);

        createCornedSuccessBox(
            "Files saved",
            "Your files have been successfully saved!"
        );
    };

    const handleHotKeyZipDownload = (event: KeyboardEvent) => {
        if (!(event.ctrlKey && event.code === "KeyS")) {
            return;
        }
        event.preventDefault();
        handleZipDownload();
    };

    useEffect(() => {
        if (alertBoxType !== AlertTypes.SUCCESS && isAlertDisplayed) {
            return;
        }
        document.addEventListener("keydown", handleHotKeyZipDownload);

        return () => {
            document.removeEventListener("keydown", handleHotKeyZipDownload);
        };
    });

    return (
        <button className="styled-file-io-button" onClick={handleZipDownload}>
            {isDownloaded ? buttonLabel : <Loader />}
        </button>
    );
};

const FileIOPanel = (): ReactElement => {
    return (
        <div className="zip-manager">
            <UploadButton buttonLabel={"OPEN PROJECT"} />
            <DownloadButton buttonLabel={"EXPORT PROJECT"} />
        </div>
    );
};

export default FileIOPanel;
