export default class Api {
    public static async post(
        endpoint: string,
        requestBody: BodyInit,
        abortSignal?: AbortSignal
    ): Promise<any> {
        const response: Response = await fetch(endpoint, {
            method: "POST",
            body: requestBody,
            signal: abortSignal,
        });

        if (response.ok) {
            return await response.text();  
        }

        const parsedResponse = await response.json();

        if(!parsedResponse?.errors) {
            throw new Error("Unexpected server error");
        }

        return parsedResponse.errors;
    }
}
