import { ReactElement } from "react";
import "./style.css";

export interface ISettingsButton {
    tooltipText?: string;
    children: ReactElement;
}

const TooltipWrapper = ({
    tooltipText = "",
    children,
}: ISettingsButton): ReactElement => {
    return (
        <div className="tooltip-wrapper">
            <div className="tooltip-text">{tooltipText}</div>
            {children}
        </div>
    );
};

export default TooltipWrapper;
