const OriginalResizeObserver = window.ResizeObserver;

function setupResizeObserver(): void {
    // @ts-ignore
    window.ResizeObserver = function (
        callback: ResizeObserverCallback
    ): ResizeObserver {
        const wrappedCallback = (
            entries: ResizeObserverEntry[],
            observer: ResizeObserver
        ) => {
            window.requestAnimationFrame(() => {
                callback(entries, observer);
            });
        };
        return new OriginalResizeObserver(wrappedCallback);
    };

    for (const staticMethod in OriginalResizeObserver) {
        if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
            // @ts-ignore
            window.ResizeObserver[staticMethod] =
                // @ts-ignore
                OriginalResizeObserver[staticMethod];
        }
    }
}

export default setupResizeObserver;
