import { ReactElement } from "react";
import CodeEditorSettingsToolbar from "@components/CodeEditorSettingsToolbar"

import "./style.css";
import FilesActionsToolbar from "@components/FilesActionsToolbar";

const WorkspaceToolbar = (): ReactElement => {
    return (
        <div className="workspace-toolbar">
            <CodeEditorSettingsToolbar />
            <FilesActionsToolbar />
        </div>
    );
}

export default WorkspaceToolbar;