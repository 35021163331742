import { Fragment, useState, type ReactElement } from "react";
import { ThemeProvider } from "@mui/styles";
import componentsLibraryMeta from "@components/ComponentsLibrary/components";
import theme from "@domains/Theme/Theme";
import ComponentSelect from "@components/ComponentSelect";
import OutLinedTextArea from "@components/OutLinedTextArea";
import ErrorBoundary from "@components/ErrorBoundary";
import "./style.css";

const componentsMetaKeys: string[] = Object.keys(componentsLibraryMeta);

const ComponentsLibrary = (): ReactElement => {
    const [selectedComponent, setSelectedComponent] = useState<string>(
        componentsMetaKeys[0]
    );

    // eslint-disable-next-line
    const [error, setError] = useState(null);

    const LibraryComponentSelect = (): ReactElement => {
        return (
            <div className="choosing-component-container">
                <span className="library-block-title">Element type</span>
                <div className="select-container">
                    <ComponentSelect
                        label={selectedComponent}
                        options={componentsMetaKeys}
                        onSelect={setSelectedComponent}
                    />
                </div>
            </div>
        );
    };

    const LibraryComponent = (): ReactElement => {
        return (
            <div className="component-view-container">
                <span className="library-block-title">Element view</span>
                <div className="component-container">
                    <div className="imported-component">
                        {componentsLibraryMeta[selectedComponent].component}
                    </div>
                </div>
            </div>
        );
    };

    const LibraryComponentGuide = (): ReactElement => {
        return (
            <Fragment>
                <OutLinedTextArea
                    label="Import area"
                    codeText={componentsLibraryMeta[selectedComponent].import}
                />
                <OutLinedTextArea
                    label="Live code"
                    codeText={componentsLibraryMeta[selectedComponent].usage}
                    props={componentsLibraryMeta[selectedComponent].props}
                />
            </Fragment>
        );
    };

    if (error || !selectedComponent) {
        return <ErrorBoundary error={error} />;
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="library-container">
                <LibraryComponentSelect />
                <LibraryComponent />
                <LibraryComponentGuide />
            </div>
        </ThemeProvider>
    );
};

export default ComponentsLibrary;
