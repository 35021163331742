import { type ReactElement, Dispatch } from "react";
import { MIN_PANEL_WIDTH } from "@utils/constants";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export type TPanelWidth = {
    left: string,
    right: string,
};

export interface IBarProps {
    setPanelWidth: Dispatch<TPanelWidth>;
}

const MAX_PANEL_WIDTH: number = 100 - MIN_PANEL_WIDTH;

const SplitBar = ({ setPanelWidth }: IBarProps): ReactElement => {
    const handleDragStart = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
        document.addEventListener("mouseup", handleDragEnd);
        document.addEventListener("mousemove", transformOnMove);
    };

    const handleDragEnd = (): void => {
        document.removeEventListener("mouseup", handleDragEnd);
        document.removeEventListener("mousemove", transformOnMove);
    };

    const transformOnMove = (event: MouseEvent): void => {
        const newPanelWidthValue: number =
            (event.clientX / window.innerWidth) * 100;

        if (
            newPanelWidthValue < MIN_PANEL_WIDTH ||
            newPanelWidthValue > MAX_PANEL_WIDTH
        ) {
            return;
        }

        setPanelWidth({left: `${newPanelWidthValue}%`, right: `${100 - newPanelWidthValue}%`});
    };

    return (
        <div className="split-bar" onMouseDown={handleDragStart}>
            <SvgSpriteIcon className={"split-line"} id={"split-line"} />
        </div>
    );
};

export default SplitBar;
