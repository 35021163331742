import {
    EditorSettingsData,
    EditorSettingsKeys,
} from "@utils/configs/editorConfigData";
import { useContext, type ReactElement } from "react";
import { LocalStorageEditorSettingsKeys } from "@utils/constants";
import SettingsControlButton from "@components/SettingsControlButton";
import TooltipWrapper from "@components/TooltipWrapper";
import CodeEditorContext from "@context/CodeEditorContext";
import LocalStorageService from "@services/localStorageService";
import "./style.css";

interface IEditorSettingsButtonsData {
    currentValue: number;
    settingsKey: EditorSettingsKeys;
    onValueChange: (newValue: number) => void;
}

const CodeEditorSettingsPanel = (): ReactElement => {
    const {
        editorFontSize,
        editorLineHeight,
        setEditorFontSize,
        setEditorLineHeight,
    } = useContext(CodeEditorContext);

    const changeLineHeight = (newValue: number): void => {
        setEditorLineHeight(newValue);
        LocalStorageService.setValue(LocalStorageEditorSettingsKeys.LINE_HEIGHT, newValue);
    };

    const changeFontSize = (newValue: number): void => {
        setEditorFontSize(newValue);
        LocalStorageService.setValue(LocalStorageEditorSettingsKeys.FONT_SIZE, newValue);
    };

    const buttonsData: IEditorSettingsButtonsData[] = [
        {
            currentValue: editorLineHeight,
            settingsKey: EditorSettingsKeys.LINE_HEIGHT,
            onValueChange: changeLineHeight,
        },
        {
            currentValue: editorFontSize,
            settingsKey: EditorSettingsKeys.FONT_SIZE,
            onValueChange: changeFontSize,
        },
    ];

    return (
        <div className="editor-settings-toolbar">
            {buttonsData.map((buttonData: IEditorSettingsButtonsData) => (
                <TooltipWrapper
                    key={buttonData.settingsKey}
                    tooltipText={
                        EditorSettingsData[buttonData.settingsKey].tooltip
                    }
                >
                    <SettingsControlButton
                        iconName={
                            EditorSettingsData[buttonData.settingsKey].iconName
                        }
                        currentValue={buttonData.currentValue}
                        values={
                            EditorSettingsData[buttonData.settingsKey].values
                        }
                        units={EditorSettingsData[buttonData.settingsKey].units}
                        onValueChange={buttonData.onValueChange}
                    />
                </TooltipWrapper>
            ))}
        </div>
    );
};

export default CodeEditorSettingsPanel;
