import { type ChangeEvent, type ReactElement } from "react";
import { type MouseEvent, type FormEvent } from "react";
import { type TUserFiles } from "@domains/File";
import { useDispatch, useSelector } from "react-redux";
import { updateFile } from "@store/slices/files";
import { useState } from "react";
import { TRootState,  } from "@store";
import UserFile from "@domains/File";
import FilesService from "@services/FilesService";
import useAlertsFactory from "@hooks/useAlertsFactory";
import "./style.css";

export interface IFileTabsRenamerProps {
    file: UserFile;
    handleCloseRename: () => void;
}

const FileTabsRenamer = ({
    file,
    handleCloseRename,
}: IFileTabsRenamerProps): ReactElement => {
    const dispatch = useDispatch();
    const { createErrorBox } = useAlertsFactory();

    const [fileName, setFileName] = useState<string>(file.getFullName());

    const serializedFiles = useSelector((state: TRootState) => state.projectFiles.files);
    const files: TUserFiles = FilesService.fromSerializableFiles(serializedFiles);
    
    const handleRenameSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (fileName === "") {
            createErrorBox(
                "File error",
                "The name of file can not be empty!"
            );
            handleCloseRename();
            return;
        }
        file.setFullName(fileName);

        const filesCopy = FilesService.excludeFileFromFilesList(file, files);

        file = FilesService.processFileNameDuplicates(file, filesCopy);

        dispatch(updateFile(file.toSerializable()));
        handleCloseRename();
    };

    const handleFormCLick = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        setFileName(event.target.value);
    };

    return (
        <form onSubmit={handleRenameSubmit} onClick={handleFormCLick}>
            <input value={fileName} onInput={handleInput} autoFocus />
            <button type="submit">OK</button>
            <button onClick={handleCloseRename}>CANCEL</button>
        </form>
    );
};

export default FileTabsRenamer;
