export interface IAbortControllerWithTimeout {
    abortController: AbortController;
    cancelAbortOnTimeout: () => void;
}

export class AbortControllerFactory {
    public static createControllerWithTimeout(
        timeout: number
    ): IAbortControllerWithTimeout {
        const abortController: AbortController = new AbortController();

        const abortControllerTimeoutId = setTimeout(
            () => abortController.abort(),
            timeout
        );

        const cancelAbortOnTimeout = () => {
            clearTimeout(abortControllerTimeoutId);
        };

        return {
            abortController,
            cancelAbortOnTimeout,
        };
    }
}
