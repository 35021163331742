export const MIN_PANEL_WIDTH: number = 25; // %
export const LINE_HEIGHT_PERCENTAGE_MULTIPLIER: number = 100;

export const enum SettingsUnits {
    PERCENT = "%",
    PIXELS = "px",
    MINUTES = "min",
}

export const enum LocalStorageEditorSettingsKeys {
    LINE_HEIGHT = "editorLineHeight",
    FONT_SIZE = "editorFontSize",
}

export const MonacoScrollBarSettings = {
    verticalScrollbarSize: 10,
    horizontalScrollbarSize: 10,
    verticalSliderSize: 8,
    horizontalSliderSize: 8,
};
