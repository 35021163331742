export const enum CompileButtonClassNames {
	COMPILE = "compile",
	STOP_COMPILE = "stop-compile",
	CANCEL = "cancel",
}

export const enum CompileButtonLabels {
	COMPILE = "compile",
	STOP_COMPILE = "stop-compile",
	CANCEL = "cancel",
}
