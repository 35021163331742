import { type ReactElement, type MouseEvent } from "react";
import "./style.css";

export const enum ModalLayouts {
	DEFAULT = 'DEFAULT',
	LOCKER = 'LOCKER',
}

export type TModalLayoutTypes = keyof typeof ModalLayouts;
	
interface IModalLayoutProps {
	layoutType?: TModalLayoutTypes;
	isModalLayoutShown: boolean;
	children?: ReactElement;
	handleClose: () => void;
}

export const stopPropagationEventInContent = (
	event: MouseEvent<HTMLDivElement>
): void => {
	event.stopPropagation();
};

const ModalLayout = ({
	layoutType = ModalLayouts.DEFAULT,
	isModalLayoutShown,
	children,
	handleClose,
}: IModalLayoutProps): ReactElement | null => {
	if (!isModalLayoutShown) {
		return null;
	}

	const isDefaultLayoutType = layoutType === ModalLayouts.DEFAULT;

	return (
		<div
			className={`modal ${
				(isDefaultLayoutType && "default-wrapper") || "locker-wrapper"
			}`}
			onClick={() => (isDefaultLayoutType && handleClose()) || null}
		>
			{children}
		</div>
	);
};

export default ModalLayout;
