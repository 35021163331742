import React, { useState } from "react";
import OutlinedTextArea from ".";

const OutlinedTextAreaWrapper = ({ type, ...props }) => {
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleColorChange = (event) => {
        setValue(event.target.value);
    };

    const handleNumberChange = (event) => {
        const newValue = parseFloat(event.target.value);
        if (!isNaN(newValue)) {
            setValue(newValue);
        }
    };

    const getOnChangeHandler = () => {
        switch (type) {
            case "color":
                return handleColorChange;
            case "number":
                return handleNumberChange;
            default:
                return handleChange;
        }
    };

    return (
        <OutlinedTextArea
            {...props}
            type={type}
            value={value}
            onChange={getOnChangeHandler()}
        />
    );
};

const outlinedTextArea = {
    component: (
        <OutlinedTextAreaWrapper
            type="text"
            label="Your Name"
            helperTxt="Enter your full name"
        />
    ),
    import: "import OutlinedTextArea from '../../common/OutlinedTextArea';",
    usage: `<OutlinedTextArea\n\ttype={"text"}\n\tlabel={"Your Name"}\n\thelperTxt={"Enter your full name"}\n\tvalue={value}\n\tonChange={(event) => setValue(event.target.value)}\n/>`,
    props: {
        type: `Specifies the type of data that will be entered in the field.\n\nAllowed values:\n\t- "text" — text input field\n\t- "color" — color picker field\n\t- "number" — numeric input field\n\nExample:\ntype="text"\n\n`,
        label: `The label for the input field, displayed above the field.\n\nExample:\nlabel="Your Name"\n\n`,
        helperTxt: `Helper text displayed below the field, providing guidance to the user.\n\nExample:\nhelperTxt="Enter your full name"\n\n`,
        value: `The current value of the input field. Typically, you manage it using React's useState.\n\nExample:\nconst [value, setValue] = useState("");\nvalue={value}\n\n`,
        onChange: `Function that is triggered each time the field's value changes.\n\nThe field processes changes differently depending on the type:\n\t- For type="text": handles text input.\n\t- For type="color": opens a color picker.\n\t- For type="number": handles numeric input.\n\nExample:\nonChange={(event) => setValue(event.target.value)}\n\n`,
        min: `The minimum acceptable value for a numeric field.\n\nWorks only with type="number".\n\nExample:\nmin={1}\n\n`,
        max: `The maximum acceptable value for a numeric field.\n\nWorks only with type="number".\n\nExample:\nmax={100}\n\n`,
    },
};

export default outlinedTextArea;
