import { memo, ReactNode, useContext, useEffect } from "react";
import WorkspaceContext from "../../context/WorkspaceContext";

const ServiceApplication = memo((): ReactNode => {
    const { previewSourceUrl, setCompilationErrors } =
        useContext(WorkspaceContext);

    try {
        const runApplication = async () => {
            import(/* webpackIgnore: true */ previewSourceUrl);
        };

        useEffect(() => {
            if (!previewSourceUrl) {
                return;
            }

            runApplication();
            // eslint-disable-next-line
        }, [previewSourceUrl]);

        return <div id="root1"></div>;
    } catch (error: any) {
        setCompilationErrors(["Preview execution failed", error.message]);
    }
});

export default ServiceApplication;
