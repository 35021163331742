import {
    useContext,
    useEffect,
    useRef,
    useState,
    type ReactElement,
} from "react";
import { IUserFileSerializable, type TUserFiles } from "@domains/File";
import {
    LINE_HEIGHT_PERCENTAGE_MULTIPLIER,
    MonacoScrollBarSettings,
} from "@utils/constants";
import { useDispatch, useSelector } from "react-redux";
// import { editorThemes, Themes } from "@domains/Theme";
import { updateFile } from "@store/slices/files";
import Editor, { OnMount, OnChange } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { TRootState } from "@store";
import UserFile from "@domains/File";
import FilesService from "@services/FilesService";
import CodeEditorContext from "@context/CodeEditorContext";
import "./style.css";

const CodeEditor = (): ReactElement => {
    // const appTheme: Themes = useSelector(
    //     (state: TRootState) => state.application.currentTheme
    // );

    const editorRef = useRef<editor.ICodeEditor | null>(null);
    const dispatch = useDispatch();

    const { editorLineHeight, editorFontSize, setIsEditorLoading } =
        useContext(CodeEditorContext);

    const currentFileId: string = useSelector(
        (state: TRootState) => state.projectFiles.currentFileId
    );
    const serializedFiles: IUserFileSerializable[] = useSelector(
        (state: TRootState) => state.projectFiles.files
    );
    const [files, setFiles] = useState<TUserFiles>(
        FilesService.fromSerializableFiles(serializedFiles)
    );
    const currentFile: UserFile | undefined = files.find(
        (file) => file.getId() === currentFileId
    );

    const handleEditorChange: OnChange = (value: string | undefined) => {
        if (!value || !currentFile) {
            return;
        }

        const updatedFile: UserFile = new UserFile(
            currentFile.getId(),
            currentFile.getName(),
            currentFile.getExtension(),
            false,
            value,
            currentFile.getCachedState()
        );

        const serializableFile: IUserFileSerializable =
            updatedFile.toSerializable();
        dispatch(updateFile(serializableFile));
    };

    const handleEditorDidMount: OnMount = (editor: any, monaco: any) => {
        editorRef.current = editor;
        setIsEditorLoading(false);
    };

    useEffect(() => {
        setFiles(FilesService.fromSerializableFiles(serializedFiles));
        // eslint-disable-next-line
    }, [serializedFiles]);

    useEffect(() => {
        editorRef.current?.focus();
    }, [currentFileId]);

    if (!currentFile) {
        return <h1>Loading...</h1>;
    }

    return (
        <div className="editor-container">
            <Editor
                height="100%"
                language="plaintext"
                path={currentFile.getFullName()}
                defaultLanguage="javascript"
                value={currentFile?.getContent()}
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                options={{
                    lineHeight:
                        editorLineHeight / LINE_HEIGHT_PERCENTAGE_MULTIPLIER,
                    fontSize: editorFontSize,
                    scrollbar:
                        MonacoScrollBarSettings as editor.IEditorScrollbarOptions,
                }}
            />
        </div>
    );
};

export default CodeEditor;
