import IdService from "@services/IdService";
export type TUserFileContent = string;
export type TUserFileExtension = string | undefined;
export type TUserFileIsDownloaded = boolean;
export type TUserFileIsCached = boolean;
export type TUserFiles = UserFile[];
export type TUserFilesError = string;

export interface IUserFileSerializable {
    id: string;
    name: string;
    fullName: string;
    extension: TUserFileExtension;
    downloadedState: TUserFileIsDownloaded;
    content: TUserFileContent;
    cachedState: TUserFileIsCached;
}

export default class UserFile {
    private id: string;
    private name: string;
    private fullName: string;
    private extension: TUserFileExtension;
    private downloadedState: TUserFileIsDownloaded;
    private content: TUserFileContent;
    private cachedState: TUserFileIsCached;

    constructor(
        id: string = IdService.generateUniqueId(),
        name: string = "index",
        extension = "js",
        isDownloaded: TUserFileIsDownloaded = false,
        content: TUserFileContent = "",
        cachedState: TUserFileIsCached = false,
    ) {
        this.id = id;
        this.name = name;
        this.extension = extension;
        this.downloadedState = isDownloaded;
        this.content = content;
        this.fullName = this.createFullName(name, extension);
        this.cachedState = cachedState;
    }
    
    public static fromSerializable(data: IUserFileSerializable): UserFile {
        return new UserFile(
            data.id,
            data.name,
            data.extension,
            data.downloadedState,
            data.content,
            data.cachedState
        );
    }

    public toSerializable(): IUserFileSerializable {
        return {
            id: this.id,
            name: this.name,
            fullName: this.fullName,
            extension: this.extension,
            downloadedState: this.downloadedState,
            content: this.content,
            cachedState: this.cachedState,
        };
    }
    
    public setId(id: string): void {
        this.id = id;
    }

    public setName(name: string): void {
        this.name = name;
        this.fullName = this.createFullName(name, this.extension);
    }

    public setExtension(extension: TUserFileExtension): void {
        this.extension = extension;
        this.fullName = this.createFullName(this.name, extension);
    }

    public setDownloadedState(state: TUserFileIsDownloaded): void {
        this.downloadedState = state;
    }

    public setFullName(fullName: string): void {
        this.fullName = fullName;
        this.name = this.getNameFromFullName(fullName);
        this.extension = this.getExtensionFromFullName(fullName);
    }

    public setContent(content: TUserFileContent): void {
        this.content = content;
    }

    public setCachedState(state: TUserFileIsCached): void {
        this.cachedState = state;
    }

    public getId(): string {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getExtension(): TUserFileExtension {
        return this.extension;
    }

    public getDownloadedState(): TUserFileIsDownloaded {
        return this.downloadedState;
    }

    public getFullName(): string {
        return this.fullName;
    }

    public getContent(): TUserFileContent {
        return this.content;
    }

    public getCachedState(): boolean {
        return this.cachedState;
    }

    private createFullName(
        name: string,
        extension: TUserFileExtension
    ): string {
        if (!extension) {
            return name;
        }
        return `${name}.${extension}`;
    }

    private getNameFromFullName(fullName: string): string {
        return fullName.split(".")[0];
    }

    private getExtensionFromFullName(fullName: string): TUserFileExtension {
        return fullName.split(".")[1];
    }
}
