export const enum FileIOErrorsTypes {
    UPLOAD = "Upload Error",
    EXPORT = "Export Error",
}

export const enum FileIOErrors {
    NOT_INDEX_FILE_EXISTS_ERROR = "The project must contain a file named 'index.js'",
    FOLDER_EXISTS_ERROR = "The archive must not contain folders!",
    EMPTY_FILE_ERROR = "'index.js' should not be empty!",
};

export const enum UploadButtonTypes {
    STYLED = "styled-file-io-button",
    PAINTED = "button-with-svg",
}
