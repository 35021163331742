import { ReactElement, ReactNode } from "react";
import alertBoxData from "./AlertBox/meta";
import alertTextData from "./AlertText/meta";
import anchorLinkData from "./AnchorLink/meta";
import codeSnippetData from "./CodeSnippet/meta";
import styledButtonData from "./StyledButton/meta";
import styledLinearProgressData from "./StyledLinearProgress/meta";
import outlinedDropdownData from "./OutlinedDropdown/meta";
import outlinedTextAreaData from "./OutlinedTextArea/meta";
import FileUploaderData from "./FileUploader/meta";

export interface IComponentsData {
    component: ReactElement | ReactNode | any;
    import: string;
    usage: string;
    props: any;
}

const componentsLibraryMeta: Record<string, IComponentsData> = {
    "Outlined TextArea": outlinedTextAreaData,
    "Outlined DropDown": outlinedDropdownData,
    "FileUploader": FileUploaderData,
    "Styled Button": styledButtonData,
    "AlertText": alertTextData,
    "AlertBox": alertBoxData,
    "Code Snippet": codeSnippetData,
    "Anchor Link": anchorLinkData,
    "Styled Linear Progress": styledLinearProgressData,
};

export default componentsLibraryMeta;
