import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import Alert, { AlertTypes } from "@domains/Alert";

export interface IAlertsSlice {
    alertBox: Alert | null;
    type: AlertTypes | "",
    isDisplayed: boolean;
    alertId: number,
    timeoutValue: number,
}

const initialState: IAlertsSlice = {
    alertBox: null,
    type: "",
    isDisplayed: false,
    alertId: 0,
    timeoutValue: 0,
};

export const alertsSlice: Slice<IAlertsSlice> = createSlice({
    name: "application",
    initialState,
    reducers: {
        setAlertBox: (state, action: PayloadAction<Alert>) => {
            state.type = action.payload.getType();
            state.alertBox = action.payload;
            state.isDisplayed = true;
        },
        setAlertIsDisplayed: (state, action: PayloadAction<boolean>) => {
            state.isDisplayed = action.payload;
            if (!action.payload) {
                state.alertBox = null;
                state.type = "";
            }
        },
        deleteAlert(state, action: any) {
            state.alertBox = null;
            state.isDisplayed = false;
            state.type = "";
            state.alertId = 0;
            state.timeoutValue = 0;
        },
    },
});

export const {
    setAlertBox,
    setAlertIsDisplayed,
    deleteAlert,
} = alertsSlice.actions;
export default alertsSlice.reducer;
