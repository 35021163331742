import { ReactElement, useContext } from "react";
import CodeEditorContext from "@context/CodeEditorContext";
import WorkspaceContext from "@context/WorkspaceContext";
import TooltipWrapper from "@components/TooltipWrapper";
import CompileButton from "@components/CompileButton";
import "./style.css";

const FilesActionsToolbar = (): ReactElement => {
    const { isEditorLoading } = useContext(CodeEditorContext);

    const { isPreviewCompiling, compileFiles, stopCompiling } =
        useContext(WorkspaceContext);

    const compileButtonAction = !isPreviewCompiling
        ? compileFiles
        : stopCompiling;

    return (
        <div className="files-actions-toolbar">
            <TooltipWrapper tooltipText="Compile and run preview">
                <CompileButton
                    onClick={compileButtonAction}
                    isDisabled={isEditorLoading}
                    isCompiling={isPreviewCompiling}
                />
            </TooltipWrapper>
        </div>
    );
};

export default FilesActionsToolbar;
