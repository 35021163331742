import { type ReactElement, Fragment } from "react";
import AppHeader from "@components/AppHeader";
import "./style.css";

interface ICodeLayoutProps {
    children: ReactElement;
}

const WorkspaceLayout = ({ children }: ICodeLayoutProps): ReactElement => {
    return (
        <Fragment>
            <AppHeader />
            <main className="editor-layout">
                <div className="workspace">{children}</div>
            </main>
        </Fragment>
    );
};

export default WorkspaceLayout;
