import { TRootState } from "@store";
import { useSelector } from "react-redux";
import { dividerSizes } from "@components/LineDivider";
import { useEffect, useState, type ReactElement } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import LineDivider from "@components/LineDivider";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import codeTheme from "./meta";
import "./style.css";

interface IOutLinedTextAreaProps {
    label: string;
    codeText: string;
    props?: any;
    isCopyButtonDisplayed?: boolean;
}

const OutLinedTextArea = ({
    label,
    codeText = " ",
    props = null,
    isCopyButtonDisplayed = true,
}: IOutLinedTextAreaProps): ReactElement => {
    const [currentCodeTheme, setCurrentCodeTheme] = useState<any>(
        codeTheme.light
    );
    const [isTextCopied, setIsTextCopied] = useState<boolean>(false);
    const propsKeys: string[] = props && Object.keys(props);
    const appTheme = useSelector((state: TRootState) => state.application.currentTheme);

    const copyCode = () => {
        navigator.clipboard
            .writeText(codeText)
            .then(() => setIsTextCopied(true));
        window.setTimeout(() => setIsTextCopied(false), 900);
    };

    useEffect(() => {
        setCurrentCodeTheme(codeTheme[appTheme]);
    }, [appTheme]);

    return (
        <div className={`outlined-textarea-container`}>
            <span className={`textarea-label`}>{label}</span>
            <div className="textarea">
                <div className={`text-container`}>
                    <SyntaxHighlighter language="jsx" style={currentCodeTheme}>
                        {codeText || "No code available"}
                    </SyntaxHighlighter>
                    {props && (
                        <div className="instructions-block">
                            <LineDivider dividerSize={dividerSizes.LONG} />
                            <p className="live-code-subtitle">Props:</p>
                            {propsKeys?.map(
                                (key: string) =>
                                    props[key] && (
                                        <span key={key}>
                                            <span className="outlined-area-bold-text">
                                                {key.toString()}
                                            </span>
                                            : {props[key]}
                                        </span>
                                    )
                            )}
                        </div>
                    )}
                </div>
                {isCopyButtonDisplayed && (
                    <div className="copy-button-container">
                        <div className={`tooltip ${isTextCopied && "copied"}`}>
                            Copied
                        </div>
                        <div onClick={copyCode}>
                            <SvgSpriteIcon
                                className="copy-text-icon"
                                id="copy-text-icon"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OutLinedTextArea;
