import React from "react";
import StyledLinearProgress from ".";

const styledLinearProgressData = {
    component: <StyledLinearProgress value={50} variant="determinate" />,
    import: "import StyledLinearProgress from '../../../../components/common/StyledLinearProgress';",
    usage: `<StyledLinearProgress \n\tvalue={50}\n\tvariant={"determinate"}\n/>`,
    props: {
        value: `The current value of the progress indicator, shown as a percentage.\n\nAllowed values:\n0 to 100\n\nExample:\nvalue={75} displays the progress at 75% completion.\n\n`,
        variant: `Defines the progress bar style.\n\nAllowed values:\n- "determinate": Displays a static progress bar with a set value.\n- "indeterminate": Displays an animated, infinite progress bar to indicate loading without a specific percentage.\n\nExample:\nvariant="determinate" for a static progress or variant="indeterminate" for an ongoing load animation.`,
    },
};

export default styledLinearProgressData;
