import { IFormFieldMeta } from "./FeedbackForm";

export const formFieldsMeta: IFormFieldMeta[]  = [
    {id: 'name', label: 'Name', isRequired: true, placeholder: 'Enter your name'},
    {id: 'email', label: 'Email', isRequired: true, placeholder: 'Enter your email', isValidate: true},
    {id: 'orgId', label: 'Organization ID (optional)', isRequired: false, placeholder: 'Enter the organization ID',},
    {id: 'serviceId', label: 'Service ID (optional)', isRequired: false, placeholder: 'Enter the service ID',},
    {id: 'endpoint', label: 'Endpoint (optional)', isRequired: false, placeholder: 'Enter the endpoint'},
]

export const textAreaField: IFormFieldMeta = {
    id: 'feedback', label: 'Feedback', isRequired: false, placeholder: 'Enter your feedback', rows: 5
}

export const feedbackCategories = [
    { value: "question", title: "Question" },
    { value: "bug", title: "Bug" },
    { value: "feedback", title: "Feedback" },
]