import { useState, type ReactElement } from "react";
import { LocalStorageEditorSettingsKeys } from "@utils/constants";
import {
    defaultEditorSettingsValues,
    EditorSettingsKeys,
} from "@utils/configs/editorConfigData";
import CodeEditor from "@components/CodeEditor";
import FileManager from "@components/FileManager";
import CodeEditorContext from "../../context/CodeEditorContext";
import LocalStorageService from "@services/localStorageService";
import WorkspaceToolbar from "@components/WorkspaceToolbar";
import "./style.css";

const CodeEditorTab = (): ReactElement => {
    const [isEditorLoading, setIsEditorLoading] = useState<boolean>(true);

    const [editorLineHeight, setEditorLineHeight] = useState<number>(
        LocalStorageService.getValue(
            LocalStorageEditorSettingsKeys.LINE_HEIGHT,
            defaultEditorSettingsValues[EditorSettingsKeys.LINE_HEIGHT]
        ) as number
    );

    const [editorFontSize, setEditorFontSize] = useState<number>(
        LocalStorageService.getValue(
            LocalStorageEditorSettingsKeys.FONT_SIZE,
            defaultEditorSettingsValues[EditorSettingsKeys.FONT_SIZE]
        ) as number
    );

    return (
        <CodeEditorContext.Provider
            value={{
                isEditorLoading,
                setIsEditorLoading,
                editorLineHeight,
                setEditorLineHeight,
                editorFontSize,
                setEditorFontSize,
            }}
        >
            <div className="editing-area-container">
                <FileManager />
                <WorkspaceToolbar />
                <CodeEditor />
            </div>
        </CodeEditorContext.Provider>
    );
};

export default CodeEditorTab;
