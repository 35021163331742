const BASE_URL: string | undefined = process.env.REACT_APP_COMPILER_BASE_URL

if (!BASE_URL) {
    throw new Error("Cannot start the application. process.env.REACT_APP_COMPILER_BASE_URL is not defined.")
}

export const TIMEOUT: number = 2 * 60 * 1000; // 2 minutes

export const Endpoints: Record<string, string> = {
    COMPILE: `${BASE_URL}/compile`,
}

