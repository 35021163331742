import { type ReactElement, type MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "@store";
import { removeFile } from "@store/slices/files";
import { deleteAlert } from "@store/slices/alerts";
import FileTabsRenamer from "@components/FileTabRenamer";
import FileTabButtons from "@components/FileTabButtons";
import useAlertsFactory from "@hooks/useAlertsFactory";
import UserFile, { TUserFiles } from "@domains/File";
import "./style.css";
import FilesService from "@services/FilesService";

export interface IFileTabProps {
    isActive: boolean;
    setActive: () => void;
    setClosestActiveOnDelete: () => void;
    file: UserFile;
}

const FileTab = ({
    file,
    isActive,
    setActive,
    setClosestActiveOnDelete,
}: IFileTabProps): ReactElement => {
    const [isTabRenaming, setIsTabRenaming] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { createDialog, createErrorBox } = useAlertsFactory();
    const serializedFiles = useSelector((state: TRootState) => state.projectFiles.files);
    const files: TUserFiles = FilesService.fromSerializableFiles(serializedFiles);

    const handleFileDelete = (
        event: MouseEvent<HTMLElement>,
        fileId: string
    ): void => {
        event.stopPropagation();

        const deleteActions = () => {
            if (files.length === 1) {
                createErrorBox(
                    "Application error",
                    "You can not delete the last file!"
                );
                return;
            }

            dispatch(removeFile(fileId));
            setClosestActiveOnDelete();
            dispatch(deleteAlert(true));
        };

        createDialog(
            "Confirm file deletion",
            `Do you really want to delete ${file.getFullName()}?`,
            "DELETE",
            deleteActions
        );
    };

    const handleRenameTriggered = (
        event: MouseEvent<HTMLElement>,
        fileId: string
    ): void => {
        event.stopPropagation();
        setIsTabRenaming(true);
        document.addEventListener("click", handleCloseRename);
    };

    const handleCloseRename = (): void => {
        setIsTabRenaming(false);
        document.removeEventListener("click", handleCloseRename);
    };

    const FileTabName = (): ReactElement => {
        if (!isTabRenaming) {
            return <div className="file-tab-name">{file.getFullName()}</div>;
        }
        return (
            <FileTabsRenamer
                file={file}
                handleCloseRename={handleCloseRename}
            />
        );
    };

    return (
        <div
            className={`file-tab 
                ${isActive && "active"} 
                ${isTabRenaming && "renaming"}
            `}
            onClick={setActive}
        >
            <div className="file-tab-body">
                <FileTabName />
                {!isTabRenaming && (
                    <FileTabButtons
                        onDelete={handleFileDelete}
                        onRename={handleRenameTriggered}
                        fileId={file.getId()!}
                    />
                )}
            </div>
        </div>
    );
};

export default FileTab;
