import { type ReactElement } from "react";
import { LoaderSizes, defaultLoaderSize } from "./meta";
import "./style.css";

export interface ILoaderProps {
	loaderSize?: LoaderSizes;
}

const Loader = ({ loaderSize = defaultLoaderSize }: ILoaderProps): ReactElement => {
	return <span className={`loader loader-size-${loaderSize}`} />;
};

export default Loader;
