export type TTheme = string;

export enum Themes {
    LIGHT = "light",
    DARK = "dark",
}

export const paletteDark: string = "#1f1f1f";
export const paletteLight: string = "#F6F6F6";

export const defaultTheme: Themes = Themes.LIGHT;

export const themeKey: string = "theme";

export const applicationThemes: Record<Themes, TTheme> = {
    [Themes.LIGHT]: "light",
    [Themes.DARK]: "dark",
};

export const editorThemes: Record<Themes, TTheme> = {
    [Themes.LIGHT]: "vs",
    [Themes.DARK]: "vs-dark",
};

export const backgroundColors: Record<Themes, string> = {
    [Themes.LIGHT]: "#F6F6F6",
    [Themes.DARK]: "#1f1f1f",
};
