export type TTimeout = { timeoutId: number | NodeJS.Timeout | null, timeoutValue: number }

export enum AlertTypes {
    DIALOG = "dialog",
    INFO = "info",
    ERROR = "error",
    SUCCESS = "success",
    WARNING = "warning",
}

export const AlertButtonsLabels = {
    OK: "OK",
    SUBMIT: "SUBMIT",
    DELETE: "DELETE",
    CANCEL: "CANCEL",
};

export interface AlertOptions {
    type?: AlertTypes;
    title?: string;
    message?: string;
    timeout?: TTimeout | null;
    submitButtonLabel?: string;
    cancelButtonLabel?: string;
    submitAction?: () => void;
    cancelAction?: () => void;
}

export default class Alert {
    private type: AlertTypes;
    private title: string;
    private message: string;
    private timeout: TTimeout | null;
    private submitButtonLabel: string;
    private cancelButtonLabel: string;
    private submitAction: () => void | null;
    private cancelAction: () => void | null;

    constructor({
        type = AlertTypes.INFO,
        title = "",
        message = "",
        timeout = null,
        submitButtonLabel = AlertButtonsLabels.SUBMIT,
        cancelButtonLabel = AlertButtonsLabels.CANCEL,
        submitAction = () => null,
        cancelAction = () => null,
    }: AlertOptions) {
        this.type = type;
        this.title = title;
        this.message = message;
        this.timeout = timeout;
        this.submitButtonLabel = submitButtonLabel;
        this.cancelButtonLabel = cancelButtonLabel;
        this.submitAction = submitAction;
        this.cancelAction = cancelAction;
    }

    public getType(): AlertTypes {
        return this.type;
    }

    public getTitle(): string {
        return this.title;
    }

    public getMessage(): string {
        return this.message;
    }

    public getTimeoutId(): TTimeout | null {
        return this.timeout;
    }

    public getSubmitButtonLabel(): string {
        return this.submitButtonLabel;
    }

    public getCancelButtonLabel(): string {
        return this.cancelButtonLabel;
    }

    public getSubmitAction(): () => void {
        return this.submitAction;
    }

    public getCancelAction(): () => void {
        return this.cancelAction;
    }

    public setTimeout(value: TTimeout) {
        this.timeout = value;
    }
}
