import UserFile, { IUserFileSerializable, type TUserFiles } from "@domains/File";

interface IFilesMeta {
    content: string;
}

export default class FilesService {
    private static getFileIndex(file: UserFile, files: TUserFiles ): number | null {
        const targetFile: UserFile | undefined = files.find((currentFile: UserFile) => currentFile.getId() === file.getId());

        if (!targetFile) {
            return null;
        }

        return files.indexOf(targetFile);
    }

    public static findFile(fileId: string, files: TUserFiles): UserFile | undefined {
        return files.find((currentFile: UserFile) => currentFile.getId() === fileId);
    }

    public static updateFileInList(file: UserFile, files: TUserFiles): void {
        const fileIndex: number | null = this.getFileIndex(file, files);

        if(!fileIndex) {
            return;
        }

        files[fileIndex] = file;
    }

    public static  deleteFileFromList(file: UserFile, files: TUserFiles): void {
        const fileIndex: number | null = this.getFileIndex(file, files);

        if(!fileIndex) {
            return;
        }

        files.splice(fileIndex, 1);
    }

    public static toSerializableFiles(files: TUserFiles): IUserFileSerializable[] {
        return files.map(file => file.toSerializable());
    }

    public static fromSerializableFiles(serializedFiles: IUserFileSerializable[]): TUserFiles {
        return serializedFiles.map(fileData => UserFile.fromSerializable(fileData));
    }

    public static getFilesIdsList(files: TUserFiles): string[] {
        return files.map((file: UserFile) =>
            file.getId()
        ); 
    }

    public static getFilesFullNamesList(files: TUserFiles): string[] {
        return files.map((file: UserFile) =>
            file.getFullName()
        );
    }

    public static createFilesCopy(files: TUserFiles): TUserFiles {
        return new Array(...files)
    }

    public static excludeFileFromFilesList(file: UserFile, files: TUserFiles): TUserFiles {
        const filesCopy: TUserFiles = this.createFilesCopy(files);

        return filesCopy.filter((currentFile: UserFile) => currentFile.getId() !== file.getId());
    }

    public static excludeFileIdFromIdsList(id: string, ids: string[]): string[] {
        return ids.filter((currentKey: string) => currentKey !== id);
    }

    public static processFileNameDuplicates(
        file: UserFile,
        files: TUserFiles
    ): UserFile {
        let duplicatesIndex: number = 0;
        const initialFileName: string = file.getName();
        const filesNames: string[] = this.getFilesFullNamesList(files);

        while (filesNames.includes(file.getFullName())) {
            duplicatesIndex++;
            file.setName(`${initialFileName}(${duplicatesIndex})`);
        }

        return file;
    }

    public static createFilesFromMeta(meta: IFilesMeta[]): TUserFiles {
        return meta.map(metaRecord => {
            const file = new UserFile(undefined, "index", "js", false, metaRecord.content, false);
            return file;
        });
    }

    public static async loadFileFromUserSystem(file: File): Promise<UserFile | null> {
        const fileReader = new FileReader()

        return new Promise((resolve, reject) => {
            fileReader.onload = (): void => {

                if(!fileReader.result) {
                    reject(null);
                }

                const uploadedFile = new UserFile()

                uploadedFile.setFullName(file.name);

                uploadedFile.setContent(fileReader.result!.toString());

                resolve(uploadedFile);
            }
            fileReader.onerror = (error) => {
                console.error(error);
                reject(error)
            }
            fileReader.readAsText(file)
        })
    }

    public static convertTextToFiles(files: TUserFiles): File[] {
        const convertedFiles: File[] = [];

        files.forEach((currentFile: UserFile) => {
            const blob = new Blob([currentFile.getContent()], { type: "text/javascript" });
            const file = new File([blob], currentFile.getFullName(), {
                type: "application/javascript",
            });
            
            convertedFiles.push(file);
        });

        return convertedFiles;
    }
}
