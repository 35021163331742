import { useState } from "react";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import "./styles.css";
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { feedbackCategories, formFieldsMeta, textAreaField } from "./meta";

export interface IFeedbackForm {
    closeForm: () => void;
}

export interface IFormFieldMeta  {
    id: keyof IFormFields;
    label: string;
    isRequired: boolean;
    placeholder: string;
    rows?: number;
    isValidate?: boolean;
}

export interface IFormFields {
    name: string;
    email: string;
    serviceId: string;
    orgId: string;
    feedback: string;
    endpoint: string;
}

const initialFormState: IFormFields = {
    name: "",
    email: "",
    serviceId: "",
    orgId: "",
    feedback: "",
    endpoint: "",  
};

const FeedbackForm = ({closeForm}: IFeedbackForm) => {
    const [formFields, setFormFields] = useState<IFormFields>(initialFormState)
    const [category, setCategory] = useState('');
    const [isRequestHandling, setIsRequestHandling] = useState<boolean>(false);

    const resetForm = () => {
        setFormFields(initialFormState);
    }

    const sendFeedback = async () => {
        try {
            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    source: "UI_CONSTRUCTOR",
                    name: formFields.name,
                    address: "",
                    email: formFields.email,
                    phone_no: "",
                    message_type: category,
                    subject: "",
                    message: formFields.feedback,
                    attachment_details: {},
                    org_id: formFields?.orgId,
                    service_id: formFields?.serviceId,
                    endpoint: formFields?.endpoint,
                })
            };
            if (!isRequestHandling) {
                setIsRequestHandling(true);
                const feedbackUrl: string | undefined = process?.env?.REACT_APP_FEEDBACK_ENDPOINT;
                if (!feedbackUrl) {
                    throw new Error(
                        "Cannot start the application! process.env.REACT_APP_FEEDBACK_ENDPOINT is undefined"
                    );
                }
                await fetch(feedbackUrl + "/user/message", options);
            }
        } catch (error) {
            console.log("error on feedback request: ", error);
        } finally {
            resetForm();
            setIsRequestHandling(false);
            closeForm();
        }
    };

    const isSubmitAvailable = (): boolean => {
        return Boolean(formFields.name) &&
            isEmailValid() &&
            Boolean(formFields.feedback) &&
            Boolean(category)
    };

    const EMAIL_VALIDATION_REGEX: RegExp = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isEmailValid = (): boolean => {
        return EMAIL_VALIDATION_REGEX.test(formFields.email);
    };

    const validationByField: {[index: string]: () => boolean} = {
        email: isEmailValid,
    }

    const SelectCategory = () => {
        return (
            <FormControl fullWidth>
                <InputLabel id="select-label">Select a category</InputLabel>
                <Select
                    labelId="select-label"
                    id="select"
                    value={category}
                    label="Select a category"
                    onChange={(event: SelectChangeEvent) => {
                        setCategory(event.target.value as string);
                      }}
                >
                    {feedbackCategories.map(feedbackCategory => (
                        <MenuItem key={feedbackCategory.value} value={feedbackCategory.value}>{feedbackCategory.title}</MenuItem>
                    ))}
                </Select>
                </FormControl>
        )
    }

    const isFieldInvalid = (formField: IFormFieldMeta) => {
        return formField?.isValidate && !validationByField[formField.id]() && Boolean(formFields[formField.id])
    }

    return (
        <div className="feedback-form-holder feedback-form">
            <div className="form-header">
                <h2>Feedback form</h2>
            </div>
            <form className="feedback-form">
                <div className="feedback-form-fields">
                    <SelectCategory />
                    {Object.values(formFieldsMeta).map(formField => (
                        <TextField
                            key={formField.id}
                            id={formField.id}
                            value={formFields[formField.id]}
                            variant="outlined"
                            required={formField.isRequired}
                            label={formField.label}
                            placeholder={formField.placeholder}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFormFields({...formFields, [formField.id]: event.target.value});
                              }}
                            error={isFieldInvalid(formField)}
                            helperText={isFieldInvalid(formField) ? "Value is not valid!" :  " "}
                        />
                    ))}
                    <p className="describe-optional-text">
                        If your question is about the code, then attach the archive and data of your organization to speed up the support process
                    </p>
                    <TextField
                            key={textAreaField.id}
                            id={textAreaField.id}
                            value={formFields[textAreaField.id]}
                            variant="outlined"
                            required={textAreaField.isRequired}
                            label={textAreaField.label}
                            placeholder={textAreaField.placeholder}
                            multiline
                            minRows={textAreaField.rows}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFormFields({...formFields, [textAreaField.id]: event.target.value});
                              }}
                            error={isFieldInvalid(textAreaField)}
                            helperText={isFieldInvalid(textAreaField) ? "Value is not valid!" :  " "}
                        />
                </div>
                <div className="submit-btn-container">
                    <Button className="cancel-button" variant="outlined"
                        onClick={closeForm}
                    >
                        Cancel
                    </Button>
                    <Button className="submit-button" variant="contained"
                        disabled={!isSubmitAvailable() || isRequestHandling}
                        onClick={sendFeedback}>
                        {isRequestHandling ? 
                            <DoneAllIcon />
                            : <span>Confirm</span>
                        }
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default FeedbackForm;