import { ReactElement } from "react";
import SvgSpriteIcon from "@components/SvgSpriteIcon";

export const AddIcon = (): ReactElement => {
    return (
        <div className="add-icon-container">
            <SvgSpriteIcon className={"add-icon"} id={"add-icon"} />
        </div>
    );
};

export const UploadIcon = (): ReactElement => {
    return (
        <div className="upload-icon-container">
            <SvgSpriteIcon className={"upload-icon"} id={"upload-icon"} />
        </div>
    );
};
