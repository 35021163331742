import { AbortControllerFactory } from "@services/ApiService";
import { type TUserFiles } from "@domains/File";
import { Endpoints, TIMEOUT } from "@api/meta";
import { useState } from "react";
import Api from "@api";
import FilesService from "@services/FilesService";
import useAlertsFactory from "./useAlertsFactory";

const usePreviewCompiler = () => {
    const { createCornedErrorBox } = useAlertsFactory();
    const [previewSourceUrl, setPreviewSourceUrl] = useState<string>("");
    const [isCompilationCanceled, setIsCompilationCanceled] = useState<boolean>(false);
    const [compilationErrors, setCompilationErrors] = useState<string[]>([]);
    const [isPreviewCompiling, setIsPreviewCompiling] =
        useState<boolean>(false);
    const [currentAbortController, setCurrentAbortController] =
        useState<AbortController>();

    const reset = () => {
        setPreviewSourceUrl("");
        setCompilationErrors([]);
        setIsPreviewCompiling(false);
    }

    const compilePreview = async (files: TUserFiles): Promise<void> => {
        reset();

        try {
            setIsPreviewCompiling(true);

            const requestSuitableFiles: File[] =
                FilesService.convertTextToFiles(files);
            const formData = new FormData();

            requestSuitableFiles.forEach((file: File) => {
                formData.append("files", file);
            });

            const { abortController, cancelAbortOnTimeout } =
                AbortControllerFactory.createControllerWithTimeout(TIMEOUT);

            setCurrentAbortController(abortController);

            const responseData = await Api.post( // TODO API methods
                Endpoints.COMPILE,
                formData,
                abortController.signal
            );

            if (Array.isArray(responseData)) {
                setCompilationErrors(responseData);
            }

            cancelAbortOnTimeout();

            const bundleBlob = new Blob([responseData], {type: "text/javascript"}); // TODO to method
            const compiledModuleUrl = URL.createObjectURL(bundleBlob);

            if(!!previewSourceUrl) {
                URL.revokeObjectURL(previewSourceUrl);
            }

            setPreviewSourceUrl(compiledModuleUrl);
            setIsPreviewCompiling(false);
        } catch (error: any) {
            if (error?.name !== "AbortError") {
                setCompilationErrors(["Request failed", error?.message]);
                setIsPreviewCompiling(false);
                return;
            }
            if (!isCompilationCanceled) {
                createCornedErrorBox(
                    "Compilation error",
                    `${error?.message}`
                );
            }
            reset();
        }
    };
    
    const stopCompiling = (): void => {
        if (currentAbortController) {
            setIsCompilationCanceled(true);
            currentAbortController.abort();
        }
    };

    return {
        previewSourceUrl,
        setPreviewSourceUrl,
        compilationErrors,
        setCompilationErrors,
        isPreviewCompiling,
        setIsPreviewCompiling,
        compilePreview,
        stopCompiling,
        reset,
    };
};

export default usePreviewCompiler;
