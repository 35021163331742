interface SvgSpriteIconProps {
    className: string;
    id: string;
}

const SvgSpriteIcon = ({className, id}: SvgSpriteIconProps) => {
    return (
        <svg className={className}>
            <use xlinkHref={`/assets/images/icons.svg#${id}`} />
        </svg>
    )
}

export default SvgSpriteIcon;
