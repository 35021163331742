import { type PayloadAction, type Slice } from "@reduxjs/toolkit";
import { IUserFileSerializable } from "@domains/File";
import { createSlice } from "@reduxjs/toolkit";
import FilesService from "@services/FilesService";

export interface IUserFilesSlice {
    currentFileId: string;
    files: IUserFileSerializable[];
}

const files: IUserFileSerializable[] = FilesService.createFilesFromMeta([{ content: "" }]).map(file => file.toSerializable());
const initialCurrentFileKey: string = files[0].id;

const initialState: IUserFilesSlice = {
    files: files,
    currentFileId: initialCurrentFileKey,
};

export const filesSlice: Slice<IUserFilesSlice> = createSlice({
    name: "projectFiles",
    initialState,
    reducers: {
        setCurrentFileId: (state, action: PayloadAction<string>) => {
            state.currentFileId = action.payload;
        },
        updateFile: (state, action: PayloadAction<IUserFileSerializable>) => {
            if (!state.files) {
                return;
            }

            const index = state.files.findIndex((file: IUserFileSerializable) => file.id === action.payload.id);
            if (index !== -1) {
                state.files[index] = action.payload;
            }
        },
        addFile: (state, action: PayloadAction<IUserFileSerializable>) => {
            state.files.push(action.payload);
        },
        removeFile: (state, action: PayloadAction<string>) => {
            if (!state.files) {
                return;
            }
            state.files = state.files.filter((file: IUserFileSerializable) => file.id !== action.payload);
        },
        replaceFiles: (state, action: PayloadAction<IUserFileSerializable[]>) => {
            state.files = action.payload;
        },
        deleteAllFiles: (state) => {
            state.files = [];
        },
    },
});

export const {
    addFile,
    removeFile,
    updateFile,
    setCurrentFileId,
    replaceFiles,
    deleteAllFiles,
} = filesSlice.actions;
export default filesSlice.reducer;
