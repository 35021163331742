import { type ReactElement } from "react";
import "./style.css";

export interface IDefaultLayoutProps {
    children: ReactElement;
}

const DefaultLayout = ({ children }: IDefaultLayoutProps): ReactElement => {
    return <main className="default-layout">{children}</main>;
};

export default DefaultLayout;
