import { ReactElement } from "react";
import "./style.css";

interface IErrorBoundaryProps {
    error: Error | null;
}

const ErrorBoundary = ({error}: IErrorBoundaryProps): ReactElement => {
    if (!error) {
        return <></>;
    }

    return (
        <div className="error-block">
            { error && error.message }
        </div>
    );
}

export default ErrorBoundary;