import { createContext, Dispatch, SetStateAction } from "react";

export interface ICodeEditorContext {
    isEditorLoading: boolean;
    editorLineHeight: number;
    editorFontSize: number;
    setIsEditorLoading: Dispatch<SetStateAction<boolean>>;
    setEditorLineHeight: Dispatch<SetStateAction<number>>;
    setEditorFontSize: Dispatch<SetStateAction<number>>;
}

const CodeEditorContext = createContext({} as ICodeEditorContext);

export default CodeEditorContext;
