import { type ReactElement } from "react";
import { stopPropagationEventInContent, TModalLayoutTypes } from "@layouts/ModalLayout";
import { ModalLayouts } from "@layouts/ModalLayout";
import ModalLayout from "@layouts/ModalLayout";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export interface IContentModalLayoutProps {
    isModalDialogOpen: boolean;
    isCloseButtonShown?: boolean;
    isTitleShown?: boolean;
    title?: string;
    layoutType?: TModalLayoutTypes;
    children?: ReactElement;
    handleClose?: () => void;
}

const ModalDialog = ({
    isModalDialogOpen,
    isCloseButtonShown = false,
    isTitleShown = true,
    title = "",
    layoutType = ModalLayouts.DEFAULT,
    children,
    handleClose = () => !isModalDialogOpen,
}: IContentModalLayoutProps): ReactElement | null => {
    if (!isModalDialogOpen) {
        return null;
    }

    const ModalHead = (): ReactElement => {
        return (
            <div className="modal-head">
                {isTitleShown && <span className="modal-title">{title}</span>}
                {isCloseButtonShown && (
                    <div
                        className="close-button-container"
                        onClick={handleClose}
                    >
                        <SvgSpriteIcon
                            className={"close-button"}
                            id={"icon-close-button"}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <ModalLayout
            isModalLayoutShown={isModalDialogOpen}
            handleClose={handleClose}
            layoutType={layoutType}
        >
            <div
                className={`modal-dialog`}
                onClick={stopPropagationEventInContent}
            >
                <ModalHead />
                <div className="modal-body">{children}</div>
            </div>
        </ModalLayout>
    );
};

export default ModalDialog;
