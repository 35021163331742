import TSettingsData, { TSettingsRecord } from "@domains/SettingsData";
import {
    SettingsUnits,
} from "@utils/constants";

const LineHeightValues: number[] = [
    100, 120, 130, 140, 150, 160, 170, 180, 190, 200, 250,
];
const fontSizeValues: number[] = [16, 18, 20, 22, 24];

export const enum EditorSettingsKeys {
    LINE_HEIGHT = "lineHeight",
    FONT_SIZE = "fontSize",
}

export const defaultEditorSettingsValues: TSettingsRecord = {
    [EditorSettingsKeys.LINE_HEIGHT]: LineHeightValues[0],
    [EditorSettingsKeys.FONT_SIZE]: fontSizeValues[0],
};

export const EditorSettingsData: Record<EditorSettingsKeys, TSettingsData> = {
    [EditorSettingsKeys.LINE_HEIGHT]: {
        iconName: "line-height-icon",
        values: LineHeightValues,
        units: SettingsUnits.PERCENT,
        tooltip:
            "This setting changes the line spacing. LMB to increase, RMB to decrease",
    },
    [EditorSettingsKeys.FONT_SIZE]: {
        iconName: "font-size-icon",
        values: fontSizeValues,
        units: SettingsUnits.PIXELS,
        tooltip:
            "This setting changes the font size. LMB to increase, RMB to decrease",
    },
};
