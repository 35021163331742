import React from "react";
import AnchorLink from ".";

const anchorLinkData = {
    component: <AnchorLink label={"Anchor link"} href={"https://www.google.com/"} newTab={true} />,
    import: "import AnchorLink from '../../../../components/common/AnchorLink';",
    usage: `<AnchorLink \n\tlabel={"Anchor link"}\n\thref={"https://www.google.com/"}\n\tnewTab={true}\n/>`,
    props: {
        label: `The text displayed for the link.\n\nExample:\n\tlabel="Go to Section"\n\n`,
        href: `The URL or anchor destination for the link.\n\tThis can be an internal anchor (e.g., "#section") or an external URL (e.g., "https://example.com").\n\nExample:\n\thref="#section" or href="https://example.com"\n\n`,
        newTab: `Determines whether the link opens in a new tab when clicked.\n\nAllowed values:\n\ttrue | false\n\nExample:\n\tnewTab={true} opens the link in a new tab.\n\tnewTab={false} opens the link in the same tab.`,
    },
};

export default anchorLinkData;
