import {
    vs,
    vscDarkPlus,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { Themes } from "@domains/Theme";

const modifiedThemeVSDark = {
    ...vscDarkPlus,
    'pre[class*="language-"]': {
        ...vscDarkPlus['pre[class*="language-"]'],
        background: "none",
    },
    'code[class*="language-"]': {
        ...vscDarkPlus['code[class*="language-"]'],
        fontSize: "16px",
    },
};

const modifiedThemeVSLight = {
    ...vs,
    'pre[class*="language-"]': {
        ...vs['pre[class*="language-"]'],
        backgroundColor: "none",
        border: "none",
    },
    'code[class*="language-"]': {
        ...vs['code[class*="language-"]'],
        fontSize: "16px",
    },
};

const codeTheme = {
    [Themes.LIGHT]: modifiedThemeVSLight,
    [Themes.DARK]: modifiedThemeVSDark,
};

export default codeTheme;
