import { type ReactElement } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { paths } from "@router/meta";
import FileIOPanel from "@components/FileIOPanel";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import OutlinedButton from "@components/OutlinedButton";
import { setIsMainMenuDisplayed } from "@store/slices/application";
import "./style.css";

const Header = (): ReactElement => {
    const dispatch = useDispatch();

    const MainLogo = () => {
        return (
            <div className="logo-container">
                <Link to={paths.HOME.path}>
                    <SvgSpriteIcon className={"main-logo"} id={"main-logo"} />
                </Link>
            </div>
        );
    };

    const openMainMenu = (): void => {
        dispatch(setIsMainMenuDisplayed(true));
    };

    const openFAQ = (): void => {
        const faqLocation: string | undefined =
            process?.env?.REACT_APP_DEV_PORTAL_SANDBOX_PAGE;

        if (!faqLocation) {
            throw new Error(
                "Cannot start the application! process.env.REACT_APP_DEV_PORTAL_SANDBOX_PAGE is undefined"
            );
        }

        window.location.href = faqLocation;
    };

    return (
        <header>
            <div className="left-nav-side nav-side">
                <MainLogo />
                <FileIOPanel />
            </div>
            <div className="right-nav-side nav-side">
                <OutlinedButton label="MENU" action={openMainMenu} />
                <OutlinedButton label="FAQ" action={openFAQ} />
            </div>
        </header>
    );
};

export default Header;
