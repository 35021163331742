import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export const dividerSizes = {
    SMALL: "small",
    LONG: "long",
};

const LineDivider = ({ dividerSize = dividerSizes.SMALL }) => {
    return (
        <div className="divider">
            {dividerSize === dividerSizes.SMALL && (
                <SvgSpriteIcon
                    className={"long-hyphen-icon"}
                    id={"long-hyphen-icon"}
                />
            )}
            {dividerSize === dividerSizes.LONG && (
                <div className="div-divider"></div>
            )}
        </div>
    );
};

export default LineDivider;
