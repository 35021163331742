import { type TRootState } from "@store";
import { type ReactElement } from "react";
import { type TUserFiles } from "@domains/File";
import { useDispatch, useSelector } from "react-redux";
import { addFile, setCurrentFileId, updateFile } from "@store/slices/files";
import UserFile from "@domains/File";
import FilesService from "@services/FilesService";
import ToolBar from "@components/EditorToolbar";
import FileTab from "@components/FileTab";
import "./style.css";

const FileManager = (): ReactElement => {
    const dispatch = useDispatch();

    const serializedFiles = useSelector(
        (state: TRootState) => state.projectFiles.files
    );
    const files: TUserFiles =
        FilesService.fromSerializableFiles(serializedFiles);

    const currentFileId: string = useSelector(
        (state: TRootState) => state.projectFiles.currentFileId
    );
    const filesKeys: string[] = FilesService.getFilesIdsList(files);

    const selectFile = (fileId: string): void => {
        dispatch(setCurrentFileId(fileId));
    };

    const setClosestTabActive = (fileId: string): void => {
        if (fileId !== currentFileId || files.length === 1) {
            return;
        }

        const updatedFilesKeys: string[] =
            FilesService.excludeFileIdFromIdsList(fileId, filesKeys);

        selectFile(updatedFilesKeys[0]);
    };

    const addFileToProject = (file: UserFile): void => {
        const newFile: UserFile = FilesService.processFileNameDuplicates(
            file,
            files
        );

        dispatch(updateFile(newFile.toSerializable()));
        dispatch(addFile(newFile.toSerializable()));
        selectFile(newFile.getId());
    };

    const handleUploadFile = async (): Promise<void> => {
        const fileInput: HTMLInputElement = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".js";

        fileInput.addEventListener("change", async () => {
            const { files } = fileInput;

            if (!files?.length) {
                return;
            }

            const file: UserFile | null =
                await FilesService.loadFileFromUserSystem(files[0]);

            if (!file) {
                return;
            }

            addFileToProject(file);
        });

        fileInput.click();
    };

    return (
        <div className="file-explorer" id="file-explorer">
            <ToolBar
                onAddFile={() => addFileToProject(new UserFile())}
                onUploadFile={handleUploadFile}
            />
            <div className="tabs-container-wrapper">
                <div className="tabs-container">
                    {files.map((file: UserFile) => (
                        <FileTab
                            key={file.getId()}
                            setActive={() => selectFile(file.getId())}
                            setClosestActiveOnDelete={() =>
                                setClosestTabActive(file.getId())
                            }
                            file={file}
                            isActive={file.getId() === currentFileId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileManager;
