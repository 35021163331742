import { type ReactElement } from "react";
import { RouterProvider } from "react-router-dom";
import { TRootState } from "@store";
import { useSelector } from "react-redux";
import MainRouter from "@router";
import Alert from "@components/AlertSelector";
import FeedbackFormModal from "@components/FeedbackFormModal";
import "@domains/Theme/style.css";
import "./style.css";

const App = (): ReactElement => {
	const appTheme = useSelector((state: TRootState) => state.application.currentTheme);
	const isAlertDisplayed = useSelector((state: TRootState) => state.alerts.isDisplayed);

	return (
		<div className={`app ${appTheme}`}>
			{isAlertDisplayed && <Alert />}
			<RouterProvider router={MainRouter} />
			<FeedbackFormModal />
		</div>
	);
};

export default App;
