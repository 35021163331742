import { previewTabs } from "./meta";
import { useContext, useEffect, useState, type ReactElement } from "react";
import { AliveScope } from "react-activation";
import PreviewExplorer from "@components/PreviewExplorer";
import WorkspaceContext from "@context/WorkspaceContext";
import "./style.css";

const PreviewArea = (): ReactElement => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const { isPreviewCompiling } =
    useContext(WorkspaceContext);

    useEffect(() => {
        if (isPreviewCompiling) {
            setActiveTab(0);
        }
    }, [isPreviewCompiling])

    return (
        <AliveScope>
            <PreviewExplorer
                activeTab={activeTab}
                previewTabs={previewTabs}
                setActiveTab={setActiveTab}
            />
            <div className="preview-body-container">
                {previewTabs[activeTab].component}
            </div>
        </AliveScope>
    );
};

export default PreviewArea;
