import { deleteAlert, setAlertBox } from "@store/slices/alerts";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "@store";
import Alert, { AlertTypes } from "@domains/Alert";

export enum Timeouts {
    ALERT = 5_000,
    RELOAD_ALERT = 100,
};

const useAlertsFactory = () => {
    const dispatch = useDispatch();

    const alertBox: Alert | null = useSelector(
        (state: TRootState) => state.alerts.alertBox
    );

    const deleteAlertFromStore = () => {
        dispatch(deleteAlert(true));
    };

    const addAlertToStoreWithTimeout = (alert: Alert) => {
        if (alertBox) {
            deleteAlertFromStore();
            setTimeout(() => dispatch(setAlertBox(alert)), Timeouts.RELOAD_ALERT);
            return;
        }

        dispatch(setAlertBox(alert));
        setTimeout(() => dispatch(deleteAlert(true)), Timeouts.ALERT);
    };

    const createDialog = (
        title: string,
        message: string,
        submitButtonLabel: string,
        submitActions: () => void
    ) => {
        const alert = new Alert({
            type: AlertTypes.DIALOG,
            title: title,
            message: message,
            submitButtonLabel: submitButtonLabel,
            submitAction: submitActions,
        });

        dispatch(setAlertBox(alert));
    };

    const createCornedSuccessBox = (title: string, message: string) => {
        const alert = new Alert({
            type: AlertTypes.SUCCESS,
            title: title,
            message: message,
        });

        addAlertToStoreWithTimeout(alert);
    };

    const createInfoBox = (message: string) => {
        if (alertBox) {
            return;
        }

        const alert = new Alert({
            type: AlertTypes.SUCCESS,
            message: message,
        });
        
        addAlertToStoreWithTimeout(alert);
    };

    const createWarningBox = (message: string) => {
        if (alertBox) {
            return;
        }

        const alert = new Alert({
            type: AlertTypes.SUCCESS,
            message: message,
        });

        addAlertToStoreWithTimeout(alert);
    };

    const createErrorBox = (title: string, message: string): void => {
        if (alertBox) {
            return;
        }

        const alert = new Alert({
            type: AlertTypes.ERROR,
            title: title,
            message: message,
        });

        addAlertToStoreWithTimeout(alert);
    };

    const createCornedErrorBox = (title: string, message: string): void => {
        if (alertBox) {
            return;
        }

        const alert = new Alert({
            type: AlertTypes.ERROR,
            title: title,
            message: message,
        });

        addAlertToStoreWithTimeout(alert);
    };

    return {
        createDialog,
        createCornedSuccessBox,
        createInfoBox,
        createWarningBox,
        createErrorBox,
        createCornedErrorBox,
    };
};

export default useAlertsFactory;
