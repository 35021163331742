import { useContext, type ReactElement } from "react";
import ErrorsList from "@components/ErrorsList";
import CustomLoader from "@components/CustomLoader";
import PreviewAreaStub from "@components/PreviewAreaStub";
import WorkspaceContext from "../../context/WorkspaceContext";
import ServiceApplication from "@components/ServiceApplication";
import KeepAlive from 'react-activation'
import "./style.css";

const PreviewAreaPanel = (): ReactElement => {
    const { previewSourceUrl, isPreviewCompiling, compilationErrors } =
        useContext(WorkspaceContext);

    if (isPreviewCompiling) {
        return <CustomLoader title="Compiling..." />;
    }

    if (compilationErrors?.length > 0) {
        return <ErrorsList errors={compilationErrors} />;
    }

    if (previewSourceUrl) {
        return <KeepAlive><ServiceApplication /></KeepAlive>;
    }

    return <PreviewAreaStub />;
};

export default PreviewAreaPanel;
