import { type ReactElement } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "./style.css";

export type TAlertColor = "success" | "info" | "warning" | "error" | "";

export interface IMUIAlert {
    alertColor: TAlertColor;
    title?: string;
    alertText: string;
}

const MUIAlert = ({
    alertColor,
    title,
    alertText,
}: IMUIAlert): ReactElement => {
    if (!alertColor) {
        return <></>;
    }

    return (
        <div className="mui-alert">
            <Alert severity={alertColor} color={alertColor}>
                <AlertTitle>{title}</AlertTitle>
                {alertText}
            </Alert>
        </div>
    );
};

export default MUIAlert;
