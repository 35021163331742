import { type ReactElement, type MouseEvent } from "react";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export type TTabButtonActionType = (
    event: MouseEvent<HTMLElement>,
    fileId: string,
) => void;

export interface IFileTabButtonsProps {
    fileId: string,
    onDelete: TTabButtonActionType;
    onRename: TTabButtonActionType;
}

export interface IFileTabButton {
    key: string;
    iconId: string;
    action: TTabButtonActionType;
}

const FileTabButtons = ({
    fileId,
    onDelete,
    onRename,
}: IFileTabButtonsProps): ReactElement => {
    const fileTabButtonsList: IFileTabButton[] = [
        {
            key: "rename",
            iconId: "icon-edit",
            action: onRename,
        },
        {
            key: "delete",
            iconId: "icon-delete",
            action: onDelete,
        },
    ];

    return (
        <div className="file-tab-menu-buttons">
            {fileTabButtonsList.map((fileTabButton: IFileTabButton) => (
                <div
                    className="icon-tab-container"
                    key={fileTabButton.key}
                    onClick={(event) => fileTabButton.action(event, fileId)}
                >
                    <SvgSpriteIcon
                        className={`menu-icon ${fileTabButton.iconId}`}
                        id={fileTabButton.iconId}
                    />
                </div>
            ))}
        </div>
    );
};

export default FileTabButtons;
