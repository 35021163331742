import { type ReactElement, useState } from "react";
import { type TUserFiles } from "@domains/File";
import MainMenu from "@components/MainMenu";
import ActionsBar from "@components/ActionsBar";
import WorkspaceLayout from "@layouts/WorkspaceLayout";
import WorkspacePanels from "@components/WorkspacePanels";
import WorkspaceContext from "../../context/WorkspaceContext";
import usePreviewCompiler from "../../hooks/usePreviewCompiler";
import FilesService from "@services/FilesService";
import { useSelector } from "react-redux";
import { TRootState } from "@store";
import "./style.css";

const Workspace = (): ReactElement => {
    // eslint-disable-next-line
    const [currentFiles, setFiles] = useState<TUserFiles>([]);
    
    const files = useSelector((state: TRootState) =>
        FilesService.fromSerializableFiles(state.projectFiles.files)
    );

    const {
        previewSourceUrl,
        isPreviewCompiling,
        compilationErrors,
        setCompilationErrors,
        compilePreview,
        stopCompiling,
    } = usePreviewCompiler();

    const compileFiles = () => {
        compilePreview(files);
    };

    return (
        <WorkspaceLayout>
            <WorkspaceContext.Provider
                value={{
                    files,
                    setFiles,
                    compileFiles,
                    stopCompiling,
                    previewSourceUrl,
                    compilationErrors,
                    isPreviewCompiling,
                    setCompilationErrors,
                }}
            >
                <MainMenu />
                <WorkspacePanels />
                <ActionsBar />
            </WorkspaceContext.Provider>
        </WorkspaceLayout>
    );
};

export default Workspace;
