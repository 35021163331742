import { ReactElement, useState, MouseEvent } from "react";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css";

export interface ISettingsButton {
    iconName: string;
    currentValue: number | string | null;
    values: Array<string | number> | null;
    units?: string;
    onValueChange: (newValue: any) => void;
}

const STARTING_VALUE_INDEX: number = 0;

const SettingsControlButton = ({
    iconName,
    currentValue,
    values = [],
    units,
    onValueChange,
}: ISettingsButton): ReactElement => {
  
    if (!values?.length) {
        throw new Error("Settings values are not given");
    }

    if (!currentValue) {
        throw new Error("Settings value is not specified");
    }

    const [valueIndex, setValueIndex] = useState<number>(
        values.indexOf(currentValue)
    );
    
    const [settingsItemValue, setSettingsItemValue] = useState<string | number>(
        values[valueIndex]
    );

    const selectNextValue = () => {
        const newValueIndex =
            valueIndex === values.length - 1
                ? STARTING_VALUE_INDEX
                : valueIndex + 1;
        const newValue: number | string = values[newValueIndex];

        setValueIndex(newValueIndex);
        setSettingsItemValue(newValue);
        onValueChange(newValue);
    };

    const selectPreviousValue = (event: MouseEvent<HTMLElement>) => {
        event?.preventDefault();
        const newValueIndex =
            valueIndex === STARTING_VALUE_INDEX
                ? values.length - 1
                : valueIndex - 1;
        const newValue: number | string = values[newValueIndex];
        
        setValueIndex(newValueIndex);
        setSettingsItemValue(newValue);
        onValueChange(newValue);
    };

    return (
        <div
            className="editor-settings-item-container"
            onClick={selectNextValue}
            onContextMenu={selectPreviousValue}
        >
            <div className="editor-settings-item">
                <SvgSpriteIcon
                    className={`settings-item-icon ${iconName}`}
                    id={iconName}
                />
                <span className="item-label">
                    {settingsItemValue} {units && units}
                </span>
            </div>
        </div>
    );
};

export default SettingsControlButton;
